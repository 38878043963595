<template>
  <div class="index">
    <el-backtop :bottom="60"></el-backtop>
    <div class="header-menu">
      <div class="header-menu_container">
        <div class="header-menu_logo">
          <a href="/en">
            <img src="~@/assets/en-logo.png" alt="CF40 RESEARCH" />
          </a>
        </div>
        <div class="header-menu_filter">
          <div class="lan-switch" @click="switchToCn">
            中文版
          </div>
          <div class="header-menu_wrap">
            <div class="header-menu_wrap_search">
              <el-input placeholder="Please enter keywords" v-model="searchForm.title" @keyup.enter.native="searchData" @clear="searchData" clearable>
                <el-button slot="append" icon="el-icon-search" @click="searchData"></el-button>
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-main">
      <el-row :gutter="30">
        <el-col :span="16" v-loading="loading">
          <el-card class="card-wrap" v-for="(item, index) in dataList" :key="index">
            <div class="card-wrap_content" @click="jumpToDetail(item)">
              <div class="img_icon" v-if="reportImage(item)">
                <el-image :src="reportImage(item)" style="max-width: 100%;">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div class="right-content">
                <div class="right-content_title">
                  <span>{{ item.product_name }}</span>
                </div>
                <div :class="{ 'right-content_desc': true, 'spacing': !reportImage(item) }">
                  {{ removeHtmlTags(item.content) }}
                </div>
                <div class="right-content_tag" v-if="item.product_label && item.product_label.length > 0">
                  <el-tag type="warning" v-for="(jtem, jndex) in item.product_label" :key="jndex">{{ jtem.label_name }}</el-tag>
                </div>
                <div :class="{ 'right-content_foot': true, 'toTop': !reportImage(item) }">
                  <span>{{ item.author }}</span>
                  <span>{{ publishTimeFormat(item.publish_time, 'YYYY-MM-DD') }}</span>
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="card-wrap" v-if="!dataList || dataList.length == 0">
            <el-empty description="暂无内容"></el-empty>
          </el-card>
          <div class="pages">
            <el-pagination
                background
                :current-page="currentPage"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :pager-count="11"
                layout="prev, pager, next"
                @size-change="dataSizeChange"
                @current-change="dataCurrentChange"
                :total="total">
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="8">
          <el-card class="card-right-class">
            <div class="class-content">
              <div :class="{ 'class-content_item': true, 'active': item.id == selectedClass }" v-for="(item, index) in reportClass" :key="index" @click="chooseClass(item.id)">
                <div class="class-content_item_icon">
                  <el-image :src="item.icon && item.icon.img ? item.icon.img : ''">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div class="class-content_item_title">{{ item.class_name }}</div>
              </div>
            </div>
          </el-card>
          <el-card class="card-right-recommend">
            <div class="recommend-title">
              <span>Featured</span>
            </div>
            <div class="recommend-content">
              <div class="recommend-content_item" v-for="(item, index) in recommendList" :key="index" @click="jumpToDetail(item)">
                <div class="recommend-content_item_desc">
                  <div class="recommend-content_item_desc_title toBottom">
                    <span>{{ item.product_name }}</span>
                  </div>
                  <div class="recommend-content_item_desc_foot">
                    <span>{{ item.author }}</span>
                    <span style="width: 90px; text-align: right">{{ publishTimeFormat(item.publish_time, 'YYYY-MM-DD') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="index-footer">
      <div class="index-footer_container">
        <div class="row footer-contact">
          <div class="footer-contact-right">
            <div class="item-cell">
              <span>Tel：</span>
              <span>{{ configObj.CONNECT_MOBILE }}</span>
            </div>
            <div class="item-cell">
              <span>Address：</span>
              <span>{{ configObj.ADDRESS }}</span>
            </div>
            <div class="item-cell">
              <span>Mail：</span>
              <span>{{ configObj.EMAIL }}</span>
            </div>
          </div>
        </div>
        <div class="copy-right">
          <span v-if="configObj.BEIAN">© 2023 <a href="http://www.beian.miit.gov.cn/" target="_blank">{{ configObj.BEIAN }}</a></span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您访问北京四十人信息科技有限公司</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { classifyList, reportList, initConfig, reportDetail } from "@/api/enreserch";
export default {
  name: "English",
  data() {
    return {
      searchForm: {
        title: ""
      },
      // 配置信息
      configObj: {
        CONNECT_MOBILE: "",
        ADDRESS: "",
        EMAIL: "",
        BEIAN: "",
        PCLOGO: ""
      },
      // 分类列表
      reportClass: [],
      // 选中的分类
      selectedClass: "",
      // 推荐阅读列表
      recommendList: [],
      // 数据加载状态
      loading: false,
      // 数据列表
      dataList: [],
      // 当前页
      currentPage: 1,
      // 每页显示的条数
      pageSize: 10,
      // 总条数
      total: 0
    }
  },
  created() {
    this.searchData();
    this.getRecommendList();
    this.getInitCofing();
    this.getReportClass();
  },
  methods: {
    // 搜索
    searchData() {
      this.currentPage = 1;
      this.getReportList();
    },
    // 获取研究报告数据列表
    async getReportList() {
      this.loading = true;
      let param = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        search: "",
        searchFields: "",
        orderBy: "publish_time",
        sortedBy: "desc"
      };
      if (this.searchForm.title && this.searchForm.title.trim()) {
        param.search = `${param.search}product_name:${this.searchForm.title}|`;
        param.searchFields = `${param.searchFields}product_name:like|`;
      }
      if (this.selectedClass) {
        param.search = `${param.search}product_class_id:${this.selectedClass}|`;
        param.searchFields = `${param.searchFields}product_class_id:=|`;
      } else {
        param.search = `${param.search}product_class_id:20,1001|`;
        param.searchFields = `${param.searchFields}product_class_id:in|`;
      }
      let res = await reportList(param);
      if (res.code == 200) {
        this.dataList = res.data.list;
        this.total = res.data.total;
      }
      this.loading = false;
    },
    // 获取分类列表
    async getReportClass() {
      let param = {
        pageSize: 50,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "desc",
        search: "type:1|type:1",
        searchFields: "type:=|type:="
      };
      this.reportClass = [];
      let res = await classifyList(param);
      if (res && res.code == 200) {
        if (res.data.list && res.data.list.length > 0) {
          for (let i = 0; i < res.data.list.length; i++) {
            let item = res.data.list[i];
            if (item.id == 20 || item.id == 1001) {
              this.reportClass.push(item);
            }
          }
        }
      }
    },
    // 设置每页显示多少条
    dataSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getReportList();
    },
    // 设置当前页
    dataCurrentChange(val) {
      this.currentPage = val;
      this.getReportList();
    },
    // 选择ID
    chooseClass(class_id) {
      if (class_id == this.selectedClass) {
        this.selectedClass = "";
      } else {
        this.selectedClass = class_id;
      }
      this.getReportList();
    },

    // 跳转到详情
    async jumpToDetail(row) {
      const statusLoading = this.$loading({
        lock: true,
        text: 'Loading......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let res = await reportDetail({ id: row.id });
      if (res) {
        const eleLink = document.createElement("a"); // 新建A标签
        eleLink.href =  window.URL.createObjectURL(res); // 下载的路径
        eleLink.style.display = "none";
        eleLink.target = "_blank";
        document.body.appendChild(eleLink);
        eleLink.click(); // 触发点击事件
        document.body.removeChild(eleLink);
      }
      statusLoading.close();
    },
    // 获取配置信息
    async getInitCofing() {
      let _This = this;
      let res = await initConfig();
      if (res && res.code == 200) {
        this.allConfig = res.data;
        if (res.data.length > 0) {
          let temp = {};
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == "CONNECT_MOBILE") {
              temp['CONNECT_MOBILE'] = item.config_value;
            } else if (item.config_key == "ADDRESS") {
              temp['ADDRESS'] = item.config_value;
            } else if (item.config_key == "EMAIL") {
              temp['EMAIL'] = item.config_value;
            } else if (item.config_key == "BEIAN") {
              temp['BEIAN'] = item.config_value;
            } else if (item.config_key == "PCLOGO") {
              temp['PCLOGO'] = item.config_value;
            }
          }
          _This.configObj = temp;
        }
      }
    },
    // 获取推荐阅读研究报告列表
    async getRecommendList() {
      let param = {
        pageSize: 10,
        currentPage: 1,
        search: "recommend:0",
        searchFields: "recommend:>",
        orderBy: "publish_time",
        sortedBy: "desc"
      };
      let res = await reportList(param);
      if (res.code == 200) {
        this.recommendList = res.data.list;
      }
    },
    // 处理发表时间
    publishTimeFormat(date, formats) {
      if (date) {
        return this.$moment(date).format(formats);
      }
      return date ? date : "";
    },
    // 处理显示的图片
    reportImage(row) {
      if (row && row.img && row.img.length > 0) {
        return row.img[0]['img'];
      }
      return false;
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '') : "暂无简介";
    },
    // 切换到英文版
    switchToCn() {
      this.$router.push({ path: "/" });
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-input.is-active .el-input__inner, .el-input__inner:focus {
    border-color: #C9A63E !important;
  }
  .el-backtop {
    color: #C9A63E;
  }
}
.index {
  height:100%;
  overflow: auto;
  &-main {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    .card-wrap {
      margin-bottom: 20px;
      cursor: pointer;
      &_content {
        display: flex;
        .img_icon {
          min-width: 240px;
          height: 180px;
          padding-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .right-content {
          padding: 5px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &_title {
            font-weight: bold;
            font-size: 20px;
            color: #303133;
          }
          &_desc {
            font-size: 14px;
            color: #909399;
            line-height: 30px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: justify;
            &.spacing {
              margin: 10px 0;
            }
          }
          &_tag {
            .el-tag {
              margin-right: 10px;
            }
          }
          &_foot {
            font-size: 14px;
            color: #909399;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.toTop {
              margin-top: 10px;
            }
          }
        }
      }
      &:hover {
        .right-content_title {
          color: #C9A63E;
        }
      }
    }
    .card-right-class {
      margin-bottom: 20px;
      .class-title {
        font-size: 16px;
        color: #303133;
        padding-bottom: 10px;
        border-bottom: 1px solid #E4E7ED;
      }
      .class-content {
        display: flex;
        flex-wrap: wrap;
        &_item {
          width: calc(100% / 3);
          margin: 10px 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &_icon {
            width: 66px;
            height: 66px;
            padding: 5px;
            border-radius: 50%;
            background-color: #f8f2f2;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &_title {
            padding-top: 10px;
            font-weight: 700;
            font-size: 16px;
          }
          &.active {
            .class-content_item_title {
              color: #C9A63E;
            }
          }
        }
      }
    }
    .card-right-recommend {
      .recommend-title {
        font-size: 18px;
        color: #303133;
        padding-bottom: 10px;
        border-bottom: 1px solid #E4E7ED;
        font-weight: bold;
      }
      .recommend-content {
        &_item {
          margin-top: 10px;
          cursor: pointer;
          display: flex;
          padding-bottom: 6px;
          border-bottom: 1px solid #E4E7ED;
          &:last-child {
            border-bottom: none;
          }
          &_icon {
            width: 35%;
            margin-right: 10px;
          }
          &_desc {
            flex: 1;
            padding: 5px 0;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &_title {
              font-size: 16px;
              color: #303133;
              &.toBottom {
                margin-bottom: 10px;
                line-height: 28px;
              }
            }
            &_foot {
              color: #606266;
              font-size: 14px;
              display: flex;
              justify-content: space-between;
            }
          }
          &:hover {
            background-color: #f8f2f2;
            .recommend-content_item_desc_title {
              color: #C9A63E;
            }
          }
        }
      }
    }
  }
  &-footer {
    margin-top: 30px;
    padding-top: 30px;
    background-image: url("~@/assets/footer-bg.jpg");
    background-repeat: no-repeat;
    background-position: 100%;
    &_container {
      max-width: 1200px;
      margin: 0 auto;
      .footer-contact {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
      .footer-contact-right {
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-left: 10px;
        font-size: 14px;
        .item-cell + .item-cell {
          margin-left: 10px;
        }
      }
      .copy-right {
        font-size: 14px;
        color: #ffffff;
        padding: 10px 0;
        text-align: center;
        a {
          color: #ffffff;
          text-decoration: none;
          &:hover {
            color: #C9A63E;
          }
        }
      }
    }
  }
}
</style>